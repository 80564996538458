import * as React from 'react';
import Layout from '@components/layout';
import { ErrorPagesDTO } from '@DTO/ErrorPagesDTO';
import ErrorPagesComponents from '@components/layout/ErrorPagesComponents/ErrorPagesComponents';

// markup
const NotFoundPage = () => {
  const pageData = ErrorPagesDTO();

  return (
    <Layout
      metaData={pageData.metaData}
      breadcrumbs={null}
      stickedForm={pageData.stickedForm}
      bannerContactInfo={undefined}
    >
      <ErrorPagesComponents data={pageData.data} />
    </Layout>
  );
};

export default NotFoundPage;
